/** @jsx jsx */
import React from "react"
import { Box, Flex, Card, Heading, Button, jsx } from "theme-ui"
import { Link } from "gatsby"

interface TopicCardProps {
  image: string
  header: string
  isBestSelling?: boolean
  href: string
}

const TopicCard = (props: TopicCardProps): JSX.Element => {
  const { image, header, isBestSelling, href } = props

  return (
    <Card m={3} sx={{ position: "relative" }}>
      {isBestSelling && (
        <Box
          bg="#F4F427"
          px={2}
          py={1}
          sx={{
            position: "absolute",
            left: "50%",
            top: "0%",
            transform: "translate(-50%, -100%)",
            border: "1px solid black",
            fontWeight: "bold",
          }}
        >
          Best Selling
        </Box>
      )}
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: ["230px", "280px"],
          height: ["230px", "280px"],
        }}
      >
        <img src={image} alt={header} />
        <Heading mt={3}>{header}</Heading>
        <Link to={href}>
          <Button sx={{ cursor: "pointer" }} mt={3}>
            Learn more
          </Button>
        </Link>
      </Flex>
    </Card>
  )
}

TopicCard.defaultProps = {
  isBestSelling: false,
}

export default TopicCard
