import React from "react"
import { Text, Flex } from "theme-ui"

interface EventCardProps {
  month: string
  date: string
  event: string
}

const EventCard = (props: EventCardProps) => {
  return (
    <Flex sx={{ alignItems: "flex-end" }}>
      <Flex
        p={[1,1,2]}
        sx={{
          borderRadius: "100%",
          background: "#960701DE",
          width: "70px",
          height: "70px",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          lineHeight: "1.3",
        }}
      >
        <Text sx={{ fontSize: [1, 1, 3] }} color="#F0E066" variant="caps">
          {props.month}
        </Text>
        <Text
          color="#FFFFFF"
          variant="caps"
          sx={{ fontSize: [1, 1, 3], fontWeight: "bold" }}
        >
          {props.date}
        </Text>
      </Flex>
      <Text ml={3} my={4} color="#960701DE" sx={{ fontSize: [1, 1, 3] }}>
        {props.event}
      </Text>
    </Flex>
  )
}

export default EventCard
