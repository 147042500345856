import React from "react"
import {
  Box,
  Flex,
  Grid,
  Text,
  Label,
  Input,
  Heading,
  Button,
  Textarea,
} from "theme-ui"
import { graphql } from "gatsby"
import SEO from "../../../components/SEO"
import ContentSection from "../../../components/ContentSection"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import EventCard from "../../../components/EventCard"
import TopicCard from "../../../components/Upsc/TopicCard"
import EmptyCell from "../../../components/EmptyCell"
import ContactSection from "../../../components/Home/ContactSection"
import UpscContent from "../../../components/Upsc/UpscContent"

// images
import classRoomImage from "../../../../images/classroom.svg"
import interviewImage from "../../../../images/interview.svg"
import testImage from "../../../../images/test.svg"
import onlineCourseImage from "../../../../images/onlineCourse.svg"
import studyMaterialImage from "../../../../images/studyMaterial.svg"
import infrastructureImage from "../../../../images/infrastructure.svg"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const UpscPage = ({ data }) => (
  <Box>
    <SEO title="UPSC" />
    <Layout>
      <PageHeader>
        <Text sx={{ fontSize: [1,3], fontStyle: "italic" }}>
          SARKAR IAS ACADEMY is one of the most Trusted, Technically
          well-equipped institutions in Chennai for coaching UPSC,TNPSC, CIVIL
          ASPIRANTS WITH FREE INTERVIEW AND HIGHLY-QUALITY EDUCATIONAL RESOURCES
          CAN BE PRESENTED AS METICULOUSLY CRAFTED STUDY MATERIALS.
        </Text>
      </PageHeader>

      <ContentSection
        header="UPSC"
        noHeaderBorder={true}
        body={
          <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
            <TopicCard
              image={onlineCourseImage}
              header="Online Course"
              isBestSelling={true}
              href="/course/upsc/onlineCourse"
            />
            <TopicCard
              image={studyMaterialImage}
              header="Postal"
              isBestSelling={true}
              href="/course/upsc/postelCourse"
            />
            <TopicCard
              image={testImage}
              header="Prelims Test Series"
              href="/course/upsc/prelimsTestSeries"
            />
            <TopicCard
              image={interviewImage}
              header="Free Interview Training"
              href="/course/upsc/interviewTraining"
            />
            <TopicCard
              image={classRoomImage}
              header="Classroom Course"
              href="/course/upsc/classroomCourse"
            />
            <TopicCard
              image={testImage}
              header="Mains Test Series"
              href="/course/upsc/mainsTestSeries"
            />
            <TopicCard
              image={infrastructureImage}
              header="Best Infrastructure"
              href="/course/upsc/bestInfrastructure"
            />
          </Flex>
        }
      />
      <Box py={3} bg={"#E6E6E672"}>
        <ContentSection
          header="Important Dates"
          body={
            <Grid
              gap={4}
              columns={1}
              mt={[4, 3]}
              sx={{ maxWidth: "300px", margin: "auto" }}
            >
              <EventCard month="SEP" date="31" event="Inauguration Session" />
              <EventCard month="OCT" date="1" event="UPSC Batch 1 Starts" />
              <EventCard month="OCT" date="10" event="Mock Test series" />
              <EventCard month="OCT" date="20" event="Inauguration Session" />
            </Grid>
          }
        />
      </Box>

      <UpscContent />
      {/* Form */}
      <Box bg="#EBEBEB6F" mt={3} py={5}>
        <Grid columns={[1, 2]}>
          <Heading p={[5, 5, 7]} sx={{ fontSize: [3, null, 5] }}>
            UPSC ADMISSIONS 2020
          </Heading>
          <Box px={3}>
            <Heading variant="caps">Get in Touch</Heading>
            <Text variant="secondary">
              Please fill in the form below, if you have any queries or require
              any updates on the admission process. Our team will get in touch
              with you shortly.
            </Text>
            <Box pt={4} pb={3} pr={5}>
              <Grid
                columns={[1, 1, 2]}
                sx={{ gridRowGap: 2, gridColumnGap: 4 }}
              >
                <Box>
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="name" id="name" />
                </Box>
                <EmptyCell />
                <Box>
                  <Label htmlFor="email">Email</Label>
                  <Input type="text" name="email" id="email" />
                </Box>
                <Box>
                  <Label htmlFor="mobile">Mobile</Label>
                  <Input type="text" name="mobile" id="mobile" />
                </Box>
              </Grid>
              <Box mt={2}>
                <Label htmlFor="message">Message</Label>
                <Textarea name="message" id="message" />
              </Box>
              <Button mt={3}>Submit</Button>
            </Box>
          </Box>
        </Grid>
      </Box>

      {/* Contact section */}
      <ContactSection />
    </Layout>
  </Box>
)

export default UpscPage
