/** @jsx jsx */
import React, { useState } from "react"
import { Box, Flex, Text, jsx } from "theme-ui"

interface TabSectionProps {
  headerList: string[]
  contentList: React.ReactNode[]
}

const TabSection = (props: TabSectionProps): JSX.Element => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const { headerList, contentList } = props

  return (
    <Box
      my={5}
      sx={{ maxWidth: "1400px", marginLeft: "auto", marginRight: "auto" }}
    >
      <Flex
        sx={{
          position: "relative",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: ["column", "column", "row"],
        }}
      >
        {headerList.map((header: string, index) => {
          return (
            <a
              sx={{ cursor: "pointer" }}
              onClick={() => setCurrentTabIndex(index)}
            >
              <Text
                pb={2}
                variant={index === currentTabIndex ? "default" : "secondary"}
                sx={{
                  display: "inline-block",
                  fontSize: 3,
                  borderBottom:
                    index === currentTabIndex
                      ? theme => `3px solid ${theme.colors.primary}`
                      : "none",
                }}
              >
                {header}
              </Text>
            </a>
          )
        })}
      </Flex>
      <Box
        mt={4}
        px={[3, null, null, 5]}
        py={4}
        sx={{ boxShadow: "0 3px 6px #00000040" }}
      >
        {contentList[currentTabIndex]}
      </Box>
    </Box>
  )
}

export default TabSection
