/** @jsx jsx */
import React from "react"
import { Box, Heading, Text, jsx } from "theme-ui"
import ContentSection from "../ContentSection"
import TabSection from "../TabSection"

const MainHeading = ({ children }) => (
  <Heading mb={4} sx={{ textAlign: "center", fontSize: 3 }}>
    {children}
  </Heading>
)

const SubHeading1 = ({ children }) => (
  <Heading my={2} sx={{ fontSize: 3, fontWeight: "bold" }}>
    {children}
  </Heading>
)

const SubHeading2 = ({ children }) => (
  <Heading my={2} sx={{ fontSize: 2, fontWeight: "bold" }}>
    {children}
  </Heading>
)

const TableCaption = ({ children }) => (
  <caption sx={{ width: "100%", p: 2, bg: "accent", color: "white" }}>
    <Heading sx={{ fontSize: 3 }}>{children}</Heading>
  </caption>
)

const Table = ({ children }) => (
  <div
    sx={{
      overflowX: "auto",
    }}
  >
    <table
      sx={{
        my: 3,
        width: "100%",
        borderCollapse: "collapse",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        "& td, & th": {
          border: "1px solid rgba(0, 0, 0, 0.15)",
          p: 2,
        },
      }}
    >
      {children}
    </table>
  </div>
)

const aboutSection = (
  <Box>
    <Text>
      Civil services, which is the backbone of Indian governance constitutes all
      ministries and their departments. The Civil services and Forest Services
      exams are conducted by the UPSC. The prestige, power, charm and
      responsibility that come along with the top jobs such as the IAS, IFS,
      IPS, etc are a definite reason for anyone to choose Civil Services and
      Forest services as a career option.
    </Text>
    <Table>
      <TableCaption>ELIGIBILITY</TableCaption>
      <tbody>
        <tr>
          <td>EDUCATION</td>
          <td colSpan={3}>Any Degree (Recognized by UGC/AICTE)</td>
        </tr>
        <tr>
          <td colSpan={4}>AGE</td>
        </tr>
        <tr>
          <td></td>
          <td>Minimum Age</td>
          <td>Maximum Age</td>
          <td>No. of attempts</td>
        </tr>
        <tr>
          <td>GENERAL</td>
          <td>21</td>
          <td>32</td>
          <td>6</td>
        </tr>
        <tr>
          <td>OBC</td>
          <td>21</td>
          <td>35</td>
          <td>9</td>
        </tr>
        <tr>
          <td>SC &amp; ST</td>
          <td>21</td>
          <td>37</td>
          <td>No restriction</td>
        </tr>
      </tbody>
    </Table>
    <ul>
      <li>
        Must have attained the age of 21 on 1st August of year of examination.
      </li>
      <li>
        Must not have attained 32 years(35|37 years) on 1st August of year of
        examination.
      </li>
    </ul>

    <Table>
      <TableCaption>SCHEDULE OF EXAMINATIONS</TableCaption>
      <tbody>
        <tr>
          <td>1</td>
          <td>Notification</td>
          <td>February / March</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Preliminary Examination</td>
          <td>May / June</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Preliminary Exam Results</td>
          <td>July / August</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Main Examinations</td>
          <td>September/October</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Main Exam Results</td>
          <td>February 1st Week / 2nd Week</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Interview</td>
          <td>March - April</td>
        </tr>
        <tr>
          <td>7</td>
          <td>Final Results</td>
          <td>May</td>
        </tr>
      </tbody>
    </Table>
  </Box>
)

const structureSection = (
  <Box>
    <Text>
      Both Civil Services and Forest Services Examination consist of 3 stages as
      mentioned below:
    </Text>
    <Text>Stage 1 - Preliminary Exam (Objective Type)</Text>
    <Text>Stage 2 - Main Exam (Descriptive Type)</Text>
    <Text>Stage 3 - Interview (Oral)</Text>
    <SubHeading1>Preliminary Examination</SubHeading1>
    <Text>
      The Preliminary Examination which is common for both Civil services and
      Forest services is conducted during May or June every year. It consists of
      two papers - one is held in the forenoon and the other in the afternoon of
      the same day and both papers consist of a set of multiple choice
      questions. The forenoon session is General Studies Paper–I where questions
      are based on general awareness and other topics as mentioned in the
      syllabus. In afternoon session is CSAT – Paper II where questions based on
      Quantitative aptitude, Reasoning and Verbal ability as mentioned in
      syllabus. Candidates should note that there will be a penalty (negative
      marking) for wrong answers.
    </Text>
    <Text>
      Information in the table are based on UPSC Civil Services Examinations
      2018.
    </Text>
    <Table>
      <tbody>
        <tr>
          <th rowSpan={2}>Subject</th>
          <th rowSpan={2}>No. of Questions</th>
          <th colSpan={3}>Marks</th>
        </tr>
        <tr>
          <th>Total</th>
          <th>Correct Ans</th>
          <th>Incorrect Ans*</th>
        </tr>
        <tr>
          <td>General Studies Paper–I</td>
          <td>100</td>
          <td>200</td>
          <td>2</td>
          <td>-0.66</td>
        </tr>
        <tr>
          <td>CSAT – Paper II</td>
          <td>80</td>
          <td>200</td>
          <td>2.5</td>
          <td>-0.83</td>
        </tr>
      </tbody>
    </Table>
    <Text>
      * For Wrong answers one - third of the marks assigned to that question
      will be deducted as penalty
    </Text>
    <SubHeading2>Result</SubHeading2>
    <ul>
      <li>
        Paper I marks is used for the selection of candidates to the main
        examination.
      </li>
      <li>
        Paper II marks is qualifying paper with a minimum qualifying marks fixed
        at 33% (i.e 66 marks).
      </li>
      <li>
        Two set of results are published - one list is for those who qualified
        for Civil Services Main Examinations and other list is for those who
        qualified for Forest Services Main Examination.
      </li>
      <li>
        This is only a screening test, marks will not be word in calculating
        final results
      </li>
    </ul>
    <SubHeading1>Civil Services Main Examination</SubHeading1>
    <Text>
      The candidates who qualify for Civil Service Main Examination have to
      choose one optional subject which has two papers.
    </Text>
    <Text>
      The main examination is intended to assess the overall intellectual traits
      and depth of understanding of candidates rather than merely the range of
      their information and memory.
    </Text>
    <Text>
      The nature and standard of questions in the General Studies papers (Paper
      II to Paper V) will be such that a well-educated person will be able to
      answer them without any specialized study. The questions will be such as
      to test a candidate’s general awareness of a variety of subjects, which
      will have relevance for a career in the Civil Services. The questions are
      likely to test the candidate’s basic understanding of all relevant issues,
      ability to analyze and take a view on conflicting socio-economic goals,
      objectives and demands. The candidates must give relevant, meaningful and
      succinct answers.
    </Text>
    <Text>
      The scope of the syllabus for optional subject papers (Paper VI and Paper
      VII) for the examination is broadly of the honours degree level i.e. a
      level higher than the bachelors degree and lower than the masters degree.
      In the case of Engineering, Medical Science and Law, the level corresponds
      to the bachelors degree.
    </Text>
    <Text>
      In all, the aspirants are to appear in nine papers, details of which are
      given below:
    </Text>
    <Table>
      <tbody>
        <tr>
          <th>Subjects</th>
          <th>Papers</th>
          <th>Marks</th>
        </tr>
        <tr>
          <td>Indian Language *</td>
          <td>Paper–A</td>
          <td>300</td>
        </tr>
        <tr>
          <td>English *</td>
          <td>Paper–B</td>
          <td>300</td>
        </tr>
        <tr>
          <td>Essay</td>
          <td>Paper–I</td>
          <td>250</td>
        </tr>
        <tr>
          <td>General Studies 1</td>
          <td>Paper–II</td>
          <td>250</td>
        </tr>
        <tr>
          <td>General Studies 2</td>
          <td>Paper–III</td>
          <td>250</td>
        </tr>
        <tr>
          <td>General Studies 4</td>
          <td>Paper–IV</td>
          <td>250</td>
        </tr>
        <tr>
          <td>Optional Subject</td>
          <td>Paper–VI</td>
          <td>250</td>
        </tr>
        <tr>
          <td>Optional Subject</td>
          <td>Paper–VII</td>
          <td>250</td>
        </tr>
      </tbody>
    </Table>
    <Text>
      * Qualifying Papers - For qualifying, candidates have to secure a minimum
      of 25% in these papers.
    </Text>
    <SubHeading2>Interview</SubHeading2>
    <Text>
      Interview for Personality test will be conducted in Delhi for candidates
      who clear the Main examination. (Maximum mark : 300)
    </Text>
    <SubHeading2>Final Result</SubHeading2>
    <Text>
      Final Result of both Civil Services and Forest Service exam will be based
      on Main examination and Interview marks.
    </Text>
    <Heading my={3} sx={{ textAlign: "center", fontSize: 4 }}>
      Syllabus
    </Heading>
    <MainHeading>Preliminary Examination Syllabus</MainHeading>
    <SubHeading1>Paper - I</SubHeading1>
    <SubHeading2>GENERAL STUDIES</SubHeading2>
    <ul>
      <li>Current events of national and international importance.</li>
      <li>History of India and the Indian National Movement</li>
      <li>
        Indian and World Geography-Physical, Social and Economic Geography of
        India and the World&gt;
      </li>
      <li>
        Indian Polity and Governance-Constitution, Political System, Panchayati
        Raj, Public Policy, Rights Issues, etc.
      </li>
      <li>
        Economic and Social Development-Sustainable Development, Poverty,
        Inclusion, Demographics, Social Sector initiatives, etc.
      </li>
      <li>
        General issues on Environmental Ecology, Bio-diversity and Climate
        Change - that do not require subject specialization.
      </li>
      <li>General Science.</li>
    </ul>
    <SubHeading1>Paper - II</SubHeading1>
    <SubHeading2>CSAT</SubHeading2>
    <ul>
      <li>Comprehension</li>
      <li>Interpersonal skills including communication skills</li>
      <li>Logical reasoning and Analytical ability</li>
      <li>Decision-making and Problem-solving</li>
      <li>General mental ability</li>
      <li>Decision-making and Problem-solving</li>
      <li>
        Basic numeracy (numbers and their relations, orders of magnitude, etc.)
        (Class X level), Data interpretation (charts, graphs, tables, data
        sufficiency etc.(Class X level).)
      </li>
    </ul>

    <Box>
      <Text>
        <b>Note 1</b>: The questions will be of multiple choice, objective type.
      </Text>
      <Text>
        <b>Note 2</b>: It is mandatory for the candidate to appear in both the
        Papers of Civil Services (Prelim) Examination for the purpose of
        evaluation. Therefore a candidate will be disqualified in case he/she
        does not appear in both the papers of Civil Services (Prelim).
      </Text>
      <Text>
        <b>Note 3</b>: Appearing in any one of the papers on the examination day
        will be counted as an attempt.
      </Text>
      <Text>
        <b>Note 4</b>: If a candidate has applied but not appeared for the
        examination, it will not be counted as an attempt.
      </Text>
    </Box>
    <Box mt={4}>
      <MainHeading>Civil Services Main Examination Syllabus</MainHeading>
    </Box>
    <SubHeading1>Paper - I</SubHeading1>
    <ul>
      <li>
        <b>Essay :</b> Candidates will be required to write essays on specific
        topics given in the examination.
      </li>
      <li>
        They will be expected to keep closely to the subject of the essay to
        arrange their ideas in an orderly fashion and to write concisely. Credit
        will be given for effective and exact expression.{" "}
      </li>
    </ul>
    <SubHeading1>Paper - II</SubHeading1>
    <Text>
      <b>General Studies - I :</b> Indian Heritage and Culture, History and
      Geography of the World and Society of India.
    </Text>
    <ul>
      <li>
        Indian culture will cover the salient aspects of Art Forms, Literature
        and Architecture from ancient to modern times.
      </li>
      <li>
        Modern Indian history from about the middle of the eighteenth century
        until the present- significant events, personalities and issues.
      </li>
      <li>
        The Freedom Struggle - its various stages and important contributors
        /contributions from different parts of the country.
      </li>
      <li>
        Post-independence consolidation and reorganization within the country.
      </li>
      <li>
        History of the world will include events from 18th century such as the
        industrial revolution, world wars, redrawal of national boundaries,
        colonization, decolonization, political philosophies like communism,
        capitalism, socialism etc. their forms and effect on the society.
      </li>
      <li> Salient features of the Indian Society Diversity of India.</li>
      <li>
        Role of women and women’s organizations, population and associated
        issues, poverty and developmental issues, urbanization, their problems
        and their remedies.
      </li>
      <li>
        Effects of globalization on the Indian society. Social empowerment,
        communalism, regionalism and secularism.
      </li>
      <li> Salient features of world’s physical geography.</li>
      <li>
        Distribution of key natural resources across the world (including South
        Asia and the Indian subcontinent); factors responsible for the location
        of primary, secondary, and tertiary sector industries in various parts
        of the world (including India)
      </li>
      <li>
        Important Geophysical phenomena such as earthquakes, Tsunami, Volcanic
        activity, cyclone etc., geographical features and their location-
        changes in critical geographical features (including water bodies and
        ice-caps) and in flora and fauna and the effects of such changes.
      </li>
    </ul>
    <SubHeading1>Paper - III</SubHeading1>
    <Text>
      <b>General Studies- II :</b> Governance, Constitution, Polity, Social
      Justice and International relations.
    </Text>
    <ul>
      <li>
        Indian Constitution- historical underpinnings, evolution, features,
        amendments, significant provisions and basic structure.
      </li>
      <li>
        Functions and responsibilities of the Union and the States, issues and
        challenges pertaining to the federal structure, devolution of powers and
        finances up to local levels and challenges therein.
      </li>
      <li>
        Separation of powers between various organs dispute redressal mechanisms
        and institutions.
      </li>
      <li>
        Comparison of the Indian constitutional scheme with that of other
        countries
      </li>
      <li>
        Parliament and State Legislatures - structure, functioning, conduct of
        business, powers &amp; privileges and issues arising out of these.
      </li>
      <li>
        Structure, organization and functioning of the Executive and the
        Judiciary Ministries and Departments of the Government; pressure groups
        and formal/informal associations and their role in the Polity.
      </li>
      <li> Salient features of the Representation of People’s Act.</li>
      <li>
        Appointment to various Constitutional posts, powers, functions and
        responsibilities of various Constitutional Bodies.
      </li>
      <li> Statutory, regulatory and various quasi-judicial bodies</li>
      <li>
        Government policies and interventions for development in various sectors
        and issues arising out of their design and implementation.
      </li>
      <li>
        Development processes and the development industry- the role of NGOs,
        SHGs, various groups and associations, donors, charities, institutional
        and other stakeholders
      </li>
      <li>
        Welfare schemes for vulnerable sections of the population by the Centre
        and States and the performance of these schemes; mechanisms, laws,
        institutions and Bodies constituted for the protection and betterment of
        these vulnerable sections.
      </li>
      <li>
        Issues relating to development and management of Social Sector/Services
        relating to Health, Education, Human Resources.
      </li>
      <li> Issues relating to poverty and hunger.</li>
      <li>
        Important aspects of governance, transparency and accountability,
        e-governance- applications, models, successes, limitations,
        andpotential; citizenscharters, transparency &amp; accountability and
        institutional and other measures.
      </li>
      <li> Role of civil services in a democracy.</li>
      <li> India and its neighborhood-relations.</li>
      <li>
        Bilateral, regional and global groupings and agreements involving India
        and/or affecting India’s interests
      </li>
      <li>
        Effect of policies and politics of developed and developing countries on
        India’s interests, Indian diaspora.
      </li>
      <li>
        Important International institutions, agencies and fora- their
        structure, mandate.
      </li>
    </ul>
    <SubHeading1>Paper - IV</SubHeading1>
    <Text>
      <b>General Studies-III :</b> Technology, Economic Development,
      Bio-diversity, Environment, Security and Disaster Management.
    </Text>
    <ul>
      <li>
        Indian Economy and issues relating to planning, mobilization of
        resources, growth, development and employment.
      </li>

      <li> Inclusive growth and issues arising from it.</li>

      <li> Government Budgeting.</li>

      <li>
        Major crops cropping patterns in various parts of the country, different
        types of irrigation and irrigation systems storage, transport and
        marketing of agricultural produce and issues and related constraints;
        etechnology in the aid of farmers
      </li>

      <li>
        Issues related to direct and indirect farm subsidies and minimum support
        prices; Public Distribution System- objectives, functioning,
        limitations, revamping; issues of buffer stocks and food security;
      </li>

      <li> Technology missions; economics of animal-rearing.</li>

      <li>
        Food processing and related industries in India- scope and significance,
        location, upstream and downstream requirements, supply chain management.
      </li>

      <li> Land reforms in India.</li>

      <li>
        Effects of liberalization on the economy, changes in industrial policy
        and their effects on industrial growth.
      </li>

      <li> Infrastructure: Energy, Ports, Roads, Airports, Railways etc.</li>

      <li> Investment models.</li>

      <li>
        Science and Technology - developments and their applications and effects
        in everyday life
      </li>

      <li>
        Achievements of Indians in science &amp; technology; indigenization of
        technology and developing new technology.
      </li>

      <li>
        Awarenessinthefieldsof IT, Space, Computers, robotics, nano-technology,
        bio-technology and issues relating to intellectual property rights.
      </li>

      <li>
        Conservation, environmental pollution and degradation, environmental
        impact assessment
      </li>
      <li> Disaster and disaster management.</li>

      <li> Linkages between development and spread of extremism.</li>

      <li>
        Role of external state and non-state actors in creating challenges to
        internal security.
      </li>

      <li>
        Challenges to internal security through communication networks, role of
        media and social networking sites in internal security challenges,
        basics of Cyber security; money- laundering and its prevention
      </li>

      <li>
        Security challenges and their management in border areas; linkages of
        organized crime with terrorism
      </li>

      <li> Various Security forces and agencies and their mandate.</li>
    </ul>

    <SubHeading1>Paper - V</SubHeading1>
    <Text>
      <b>General Studies- IV :</b> Ethics, Integrity, and Aptitude
    </Text>
    <ul>
      <li>
        This paper will include questions to test the candidates’ attitude and
        approach to issues relating to integrity, probity in public life and his
        problem solving approach to various issues and conflicts faced by him in
        dealing with society. Questions may utilize the case study approach to
        determine these aspects. The following broad areas will be covered.
      </li>
    </ul>
    <Heading>Ethics and Human Interface :</Heading>
    <Text>
      Essence, determinants and consequences of Ethics in human actions;
      dimensions of ethics; ethics in private and public relationships. Human
      Values – lessons from the lives and teachings of great leaders, reformers
      and administrators; role of family, society and educational institutions
      in inculcating values.
    </Text>
    <ul>
      <li>
        Attitude: content, structure, function; its influence and relation with
        thought and behaviour; moral and political attitudes; social influence
        and persuasion.
      </li>
      <li>
        Aptitude and foundational values for Civil Service, integrity,
        impartiality and non- partisanship, objectivity,
        dedicationtopublicservice, empathy, toleranceandcompassion towards the
        weaker sections.
      </li>
      <li>
        Emotional intelligence-concepts, and their utilities and application in
        administration and governance.
      </li>
      <li>
        Contributions of moral thinkers and philosophers from India and world.
      </li>
      <li>
        Public/Civil service values and Ethics in Public administration: Status
        and problems; ethical concerns and dilemmas in government and private
        institutions; laws, rules, regulations and conscience as sources of
        ethical guidance; accountability and ethical governance; strengthening
        of ethical and moral values in governance; ethical issues in
        international relations and funding; corporate governance.
      </li>
      <li>
        Probity in Governance: Concept of public service; Philosophical basis of
        governance and probity;
      </li>
      <li>
        Information sharing and transparency in government, Right to
        Information, Codes of Ethics, Codes of Conduct, Citizen’s Charters, Work
        culture, Quality of service delivery, Utilization of public funds,
        challenges of corruption.
      </li>
      <li> Case Studies on above issues.</li>
    </ul>
    <SubHeading1>Paper - VI & Paper VII</SubHeading1>
    <Text>
      <b>Optional Subject :</b> Papers I and II
    </Text>
    <ul>
      <li>
        Candidates may choose any optional subject from amongst the list of
        subjects given in the UPSC Notification.
      </li>
    </ul>
  </Box>
)

const bookSection = (
  <Box>
    <MainHeading>UPSC – Examination Book List</MainHeading>
    <SubHeading1>GS PRELIMINARY</SubHeading1>
    <Table>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Indian History</td>
          <td>Standard 11th and 12th Standard NCERT old edition</td>
        </tr>
        <tr>
          <td>2</td>
          <td>India’s struggle for Independence</td>
          <td>Spectrum’s "A brief history of Modern India"</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Geography</td>
          <td>
            Standard 6th to 12th NCERT
            <br />
            Certificate Physical and Human Geography by Goh Cheng Leong
            <br />
            Oxford Student Atlas.
          </td>
        </tr>
        <tr>
          <td>6</td>
          <td>General Science</td>
          <td>
            Standard 9th and 10th Old NCERT
            <br />
            Class Notes
          </td>
        </tr>
        <tr>
          <td>7</td>
          <td>India Year Book</td>
          <td>Publication Division, Government of India</td>
        </tr>
      </tbody>
    </Table>
    <ul>
      <li>The respective NCERT Textbooks have to be mandatory studied.</li>
      <li>
        The Corresponding Ministry websites should also be referred to for
        latest policy updates.
      </li>
      <li>For current events read a Daily Newspaper (preferably The Hindu).</li>
    </ul>
    <SubHeading1>GS Main Examination</SubHeading1>
    <Table>
      <TableCaption>PAPER I</TableCaption>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            HISTORY AND CULTURE
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>India’s Struggle for Independence</td>
          <td>
            NCERT
            <br />
            Spectrum’s A brief history of Modern India
          </td>
        </tr>
        <tr>
          <td>2</td>
          <td>
            India Since Independence <br />
            or <br />
            India After Gandhi
          </td>
          <td>
            Bipin Chandra
            <br />
            or
            <br />
            Ramchandra Guha
          </td>
        </tr>
        <tr>
          <td>3</td>
          <td>Art and Culture Fine arts</td>
          <td>Standard 12th NCERT</td>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            SOCIETY IN INDIA
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Indian Society</td>
          <td>Standard 12th NCERT</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Social Change and Development in India</td>
          <td>Standard 12th NCERT</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Indian Society</td>
          <td>S.C.Dube / Relevant GOI Website</td>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            GEOGRAPHY
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Standard 11th &amp; 12th NCERT</td>
          <td>
            NCERT
            <br />
            Class Notes
          </td>
        </tr>
      </tbody>
    </Table>
    <Table>
      <TableCaption>PAPER II</TableCaption>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            GOVERNANCE
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>From Government to Governance</td>
          <td> Kuldeep Mathur</td>
        </tr>
        <tr>
          <td>2</td>
          <td>2nd ARC Report</td>
          <td>Published by Government of India</td>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            INTERNATIONAL RELATIONS
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Newspaper</td>
          <td>The Hindu</td>
        </tr>
      </tbody>
    </Table>
    <Table>
      <TableCaption>PAPER III</TableCaption>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            ECONOMY
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Economic Survey</td>
          <td>Government of India</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Business Line - Newspaper (Editorial)</td>
          <td>The Hindu group.</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Indian Economy Since Independence</td>
          <td>Uma Kapila</td>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            SCIENCE AND TECHNOLOGY
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Newspaper</td>
          <td>The Hindu</td>
        </tr>
        <tr>
          <td sx={{ textAlign: "center" }} colSpan={3}>
            ENVIRONMENT
          </td>
        </tr>
        <tr>
          <td>1</td>
          <td>Newspaper</td>
          <td>The Hindu</td>
        </tr>
      </tbody>
    </Table>
    <Table>
      <TableCaption>PAPER IV</TableCaption>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td>1</td>
          <td>2nd ARC Reports</td>
          <td>Government of India</td>
        </tr>
        <tr>
          <td>2</td>
          <td>From Government to Governance</td>
          <td>Kuldeep Mathur</td>
        </tr>
      </tbody>
    </Table>
    <Table>
      <TableCaption>For Reference Purpose only</TableCaption>
      <tbody>
        <tr>
          <th>S.No.</th>
          <th>BOOK</th>
          <th>AUTHOR</th>
        </tr>
        <tr>
          <td>1</td>
          <td>Competitive Exams - A Success Guide</td>
          <td>V Palanichamy IIS</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Civil Services Exam - A Complete Guide</td>
          <td>V Palanichamy IIS</td>
        </tr>
        <tr>
          <td>3</td>
          <td>At the Service of a Billion Plus</td>
          <td>Shankar Ganesh Karuppiah</td>
        </tr>
      </tbody>
    </Table>
  </Box>
)

const vacanciesSection = (
  <Box>
    <Text>
      The number of candidates that pass the preliminary examination is
      generally 11 or 12 times the number of vacancies, and the number of
      candidates selected for the final interview is twice the number of
      vacancies. As per existing policies, reservation for SC/ST/OBC is applied
      to each level of the selection process.
    </Text>
    <Table>
      <TableCaption>Vacancies & selection in different years</TableCaption>
      <tbody sx={{ textAlign: "center" }}>
        <tr>
          <th colSpan={1} rowSpan={2}>
            Year
          </th>
          <th colSpan={1} rowSpan={2}>
            No. of candidates applying for Prelims
          </th>
          <th colSpan={2} rowSpan={1}>
            No. of candidates that appeared for
          </th>
          <th colSpan={1} rowSpan={2}>
            <p>
              <span>
                No. of candidates
                <br />
                appearing for interviews
              </span>
            </p>
          </th>
          <th colSpan={1} rowSpan={2}>
            No. of candidates selected
          </th>
          <th colSpan={1} rowSpan={2}>
            <p>
              <span>
                Final
                <br />
                vacancies
              </span>
            </p>
          </th>
        </tr>
        <tr>
          <th colSpan={1} rowSpan={1}>
            Prelims
          </th>
          <th colSpan={1} rowSpan={1}>
            Mains
          </th>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2010
          </td>
          <td colSpan={1} rowSpan={1}>
            5,47,698
          </td>
          <td colSpan={1} rowSpan={1}>
            2,69,036
          </td>
          <td colSpan={1} rowSpan={1}>
            11,865
          </td>
          <td colSpan={1} rowSpan={1}>
            2,589
          </td>
          <td colSpan={1} rowSpan={1}>
            965
          </td>
          <td colSpan={1} rowSpan={1}>
            1,043
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2011
          </td>
          <td colSpan={1} rowSpan={1}>
            4,99,120
          </td>
          <td colSpan={1} rowSpan={1}>
            2,43,236
          </td>
          <td colSpan={1} rowSpan={1}>
            11,237
          </td>
          <td colSpan={1} rowSpan={1}>
            2,415
          </td>
          <td colSpan={1} rowSpan={1}>
            999
          </td>
          <td colSpan={1} rowSpan={1}>
            1,001
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2012
          </td>
          <td colSpan={1} rowSpan={1}>
            5,50,080
          </td>
          <td colSpan={1} rowSpan={1}>
            2,71,442
          </td>
          <td colSpan={1} rowSpan={1}>
            12,190
          </td>
          <td colSpan={1} rowSpan={1}>
            2,674
          </td>
          <td colSpan={1} rowSpan={1}>
            998
          </td>
          <td colSpan={1} rowSpan={1}>
            1,091
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2013
          </td>
          <td colSpan={1} rowSpan={1}>
            7,76,604
          </td>
          <td colSpan={1} rowSpan={1}>
            3,23,949
          </td>
          <td colSpan={1} rowSpan={1}>
            14,178
          </td>
          <td colSpan={1} rowSpan={1}>
            3,003
          </td>
          <td colSpan={1} rowSpan={1}>
            1,122
          </td>
          <td colSpan={1} rowSpan={1}>
            1,228
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2014
          </td>
          <td colSpan={1} rowSpan={1}>
            9,47,428
          </td>
          <td colSpan={1} rowSpan={1}>
            4,51,602
          </td>
          <td colSpan={1} rowSpan={1}>
            16,286
          </td>
          <td colSpan={1} rowSpan={1}>
            3,308
          </td>
          <td colSpan={1} rowSpan={1}>
            1,236
          </td>
          <td colSpan={1} rowSpan={1}>
            1,364
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2015
          </td>
          <td colSpan={1} rowSpan={1}>
            9,45,908
          </td>
          <td colSpan={1} rowSpan={1}>
            4,65,882
          </td>
          <td colSpan={1} rowSpan={1}>
            15,008
          </td>
          <td colSpan={1} rowSpan={1}>
            2,797
          </td>
          <td colSpan={1} rowSpan={1}>
            1,078
          </td>
          <td colSpan={1} rowSpan={1}>
            1,164
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2016
          </td>
          <td colSpan={1} rowSpan={1}>
            11,36,000
          </td>
          <td colSpan={1} rowSpan={1}>
            5,00,000
          </td>
          <td colSpan={1} rowSpan={1}>
            15,452
          </td>
          <td colSpan={1} rowSpan={1}>
            2,961
          </td>
          <td colSpan={1} rowSpan={1}>
            1,099
          </td>
          <td colSpan={1} rowSpan={1}>
            1,209
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2017
          </td>
          <td colSpan={1} rowSpan={1}>
            10,00,000 (approx.)
          </td>
          <td colSpan={1} rowSpan={1}>
            4,56,625
          </td>
          <td colSpan={1} rowSpan={1}>
            13,366
          </td>
          <td colSpan={1} rowSpan={1}>
            2,568
          </td>
          <td colSpan={1} rowSpan={1}>
            1058
          </td>
          <td colSpan={1} rowSpan={1}>
            980
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            2018
          </td>
          <td colSpan={1} rowSpan={1}>
            11,00,000 (approx.)
          </td>
          <td colSpan={1} rowSpan={1}>
            4,93,972
          </td>
          <td colSpan={1} rowSpan={1}>
            10,468
          </td>
          <td colSpan={1} rowSpan={1}>
            1,994
          </td>
          <td colSpan={1} rowSpan={1}>
            758
          </td>
          <td colSpan={1} rowSpan={1}>
            759
          </td>
        </tr>
      </tbody>
    </Table>
  </Box>
)

const cutOffSection = (
  <Box>
    <Text>
      The cut-off marks of the examination of the previous years' are given
      below:
    </Text>
    <Table>
      <TableCaption>Cut-off marks in different years</TableCaption>
      <tbody sx={{ textAlign: "center" }}>
        <tr>
          <th colSpan={1} rowSpan={2}>
            Year
          </th>
          <th colSpan={3} rowSpan={1}>
            Prelims
          </th>
          <th colSpan={2} rowSpan={1}>
            Main (out of 1750)
          </th>
          <th colSpan={2} rowSpan={1}>
            Total (out of 2025)
          </th>
        </tr>
        <tr>
          <th colSpan={1} rowSpan={1}>
            Category
          </th>
          <th colSpan={1} rowSpan={1}>
            Marks
          </th>
          <th colSpan={1} rowSpan={1}>
            Total Marks
          </th>
          <th colSpan={1} rowSpan={1}>
            Category
          </th>
          <th colSpan={1} rowSpan={1}>
            Marks
          </th>
          <th colSpan={1} rowSpan={1}>
            Category
          </th>
          <th colSpan={1} rowSpan={1}>
            Marks
          </th>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2013
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            241
          </td>
          <td colSpan={1} rowSpan={7}>
            400
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            564
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            775
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            222
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            534
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            742
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            207
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            518
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            719
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            201
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            510
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            707
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            199
          </td>
          <td colSpan={1} rowSpan={1}>
            H-1
          </td>
          <td colSpan={1} rowSpan={1}>
            510
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            725
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            184
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            502
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            718
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            163
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            410
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            613
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2014
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            205
          </td>
          <td colSpan={1} rowSpan={7}>
            385
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            678
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            889
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            204
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            631
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            844
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            182
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            631
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            830
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            174
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            619
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            811
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            167
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            609
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            816
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            113
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            575
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            778
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            115
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            449
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            713
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2015
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            107.34
          </td>
          <td colSpan={1} rowSpan={7}>
            200
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            676
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            877
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            106
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            630
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            834
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            94
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            622
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            810
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            91.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            617
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            801
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            90.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            580
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            802
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            76.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            627
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            830
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            40.00
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            504
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            697
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2016
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            116
          </td>
          <td colSpan={1} rowSpan={7}>
            200
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            787
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            988
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            110.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            745
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            951
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            99.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            739
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            937
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            96
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            730
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            920
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            75.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            713
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            927
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            72.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            740
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            951
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            40
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            545
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            817
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2017
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            105.34
          </td>
          <td colSpan={1} rowSpan={7}>
            200
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            809
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            1006
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            102.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            770
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            968
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            88.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            756
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            944
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            88.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            749
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            939
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            88.88
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            734
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            923
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            61.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            745
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            948
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            40
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            578
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            830
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={7}>
            2018
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            98
          </td>
          <td colSpan={1} rowSpan={7}>
            200
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            774
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>General</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            982
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            96.66
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            732
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>OBC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            938
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            84
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            719
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>SC</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            912
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            83.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            719
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>ST</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            912
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            73.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            711
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-1</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            899
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            53.34
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            696
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-2</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            908
          </td>
        </tr>
        <tr>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            40
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            520
          </td>
          <td colSpan={1} rowSpan={1}>
            <b>PH-3</b>
          </td>
          <td colSpan={1} rowSpan={1}>
            754
          </td>
        </tr>
      </tbody>
    </Table>
  </Box>
)

const servicesSection = (
  <Box>
    <SubHeading1>All India Services</SubHeading1>
    <ul>
      <li>Indian Administrative Service (IAS)</li>
      <li>Indian Police Service (IPS)</li>
    </ul>
    <SubHeading1>Central Services (Group A)</SubHeading1>
    <ul>
      <li>Indian Foreign Service (IFS)</li>
      <li>Indian Audit and Accounts Service (IA&AS)</li>
      <li>Indian Civil Accounts Service (ICAS)</li>
      <li>Indian Corporate Law Service (ICLS)</li>
      <li>Indian Defence Accounts Service (IDAS)</li>
      <li>Indian Defence Estates Service (IDES)</li>
      <li>Indian Information Service (IIS)</li>
      <li>Indian Ordnance Factories Service (IOFS)</li>
      <li>Indian Postal Service (IPoS)</li>
      <li>Indian P&T Accounts and Finance Service (IP&TAFS)</li>
      <li>Indian Railway Accounts Service (IRAS)</li>
      <li>Indian Railway Personnel Service (IRPS)</li>
      <li>Indian Railway Protection Force Service (IRPFS)</li>
      <li>Indian Railway Traffic Service (IRTS)</li>
      <li>Indian Revenue Service (IRS-IT)</li>
      <li>Indian Revenue Service (IRS-C&CE)</li>
      <li>Indian Trade Service (ITrS)</li>
    </ul>
    <SubHeading1>Group B Services</SubHeading1>
    <ul>
      <li>Armed Forces Headquarters Civil Services (AFHCS)</li>
      <li>Delhi, Andaman and Nicobar Islands Civil Service (DANICS)</li>
      <li>Delhi, Andaman and Nicobar Islands Police Service (DANIPS)</li>
      <li>Pondicherry Civil Service (PCS)</li>
      <li>Pondicherry Police Service (PPS)</li>
    </ul>
  </Box>
)

const UpscContent = (): JSX.Element => {
  return (
    <ContentSection
      header="About UPSC"
      noHeaderBorder={true}
      body={
        <TabSection
          headerList={[
            "About the Exam",
            "Structure & Syllabus",
            "Book List for exam",
            "Vacancies and Selection",
            "Cut Off Marks",
            "List of Services",
          ]}
          contentList={[
            aboutSection,
            structureSection,
            bookSection,
            vacanciesSection,
            cutOffSection,
            servicesSection,
          ]}
        />
      }
    />
  )
}

export default UpscContent
